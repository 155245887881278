import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const color = {
  color: "#003e20",
};

const background = {
  background: "#1dbf73",
};

export default function Header() {
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(
          "https://laravelreact.psofts.online/api/get-blog-data"
        );
        setBlogData(response.data);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    fetch();
  });

  const Location = useLocation();
  return (
    <div>
      <header class="header_section back-ground" style={background}>
        <div class="container-fluid">
          <nav class="navbar navbar-expand-lg custom_nav-container ">
            <Link class="navbar-brand" to="/">
              <span style={color}>PSOFT</span>
            </Link>

            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class=""> </span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav  ">
                <li
                  class={`nav-item ${Location.pathname === "/" ? "active" : ""
                    }`}
                >
                  <Link to={"/"} className="nav-link">
                    Home
                  </Link>
                </li>
                <li
                  class={`nav-item ${Location.pathname === "/about" ? "active" : ""
                    }`}
                >
                  <Link to={"/about"} className="nav-link">
                    About
                  </Link>
                </li>
                <li className={`nav-item dropdown`}>
                  <a
                    href=""
                    className={`nav-link dropdown-toggle nav-item ${Location.pathname === "/services" || Location.pathname === "/services/web-application"
                      || Location.pathname === "/services/seo"
                      || Location.pathname === "/services/e-commerce"
                      || Location.pathname === "/services/erp"
                      ? "bg-white text-dark"
                      : ""
                      }`}
                    id="servicesDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Services
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="servicesDropdown"
                  >
                    <Link
                      to={"/services/web-application"}
                      className={`dropdown-item ${Location.pathname === "/services/web-application"
                        ? "text-white bg-success"
                        : ""
                        }`}
                    >
                      Business Web App
                    </Link>

                    <Link
                      to={"/services/seo"}
                      className={`dropdown-item ${Location.pathname === "/services/seo"
                        ? "text-white bg-success"
                        : ""
                        }`}
                    >
                      On Page SEO
                    </Link>

                    <Link
                      to={"/services/e-commerce"}
                      className={`dropdown-item ${Location.pathname === "/services/e-commerce"
                        ? "text-white bg-success"
                        : ""
                        }`}
                    >
                      E-commerce Store
                    </Link>

                    <Link
                      to={"/services/erp"}
                      className={`dropdown-item ${Location.pathname === "/services/erp"
                        ? "text-white bg-success"
                        : ""
                        }`}
                    >
                      Custom ERP Development
                    </Link>
                  </div>
                </li>

                <li
                  class={`nav-item ${Location.pathname === "/projects" ? "active" : ""
                    }`}
                >
                  <Link to={"/projects"} className="nav-link">
                    Projects
                  </Link>
                </li>
                <li
                  class={`nav-item ${Location.pathname === "/blogs" ? "active" : ""
                    }`}
                >
                  <Link to={"/blogs"} className="nav-link">
                    Blogs
                  </Link>
                </li>


                {/* <li className={`nav-item dropdown`}>
                  <a
                    href=""
                    className={`nav-link dropdown-toggle nav-item ${Location.pathname === "/blog*"
                      ? "bg-white text-success"
                      : ""
                      }`}
                    id="servicesDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Blogs
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="servicesDropdown"
                  >
                    {blogData &&
                      blogData.blogs.map((blog) => (
                        <Link
                          key={blog.id}
                          to={`/blogs/${blog.title.replace(/\s+/g, "-").toLowerCase()}/${blog.id}`}
                          className={`dropdown-item ${Location.pathname === `/blogs/${blog.title.replace(/\s+/g, "-").toLowerCase()}`
                            ? "text-white bg-success"
                            : ""
                            }`}
                        >
                          {blog.title}
                        </Link>
                      ))}
                  
                  </div>
                </li> */}

                <li
                  class={`nav-item ${Location.pathname === "/contact-us" ? "active" : ""
                    }`}
                >
                  <Link to={"/contact-us"} className="nav-link">
                    Contact Us
                  </Link>
                </li>
              </ul>
              <div class="quote_btn-container">
                <a
                  href=""
                  data-toggle="modal"
                  data-target="#quote"
                  class="quote_btn"
                >
                  Get A Quote
                </a>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}
