import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from 'react-helmet';

export default function Projects() {
  useEffect(() => {
    document.title = "Projects"; 
  });
  return (
    <div>
      <Helmet>
        <meta property="title" content="Psoft - Project Portfolio" />
        <meta name="description" content="Discover Psoft's successful projects. Our portfolio showcases our expertise and commitment to driving business growth through tailored software solutions." />
        <meta name="keywords" content="Psoft project portfolio, business website projects, ERP systems portfolio, e-commerce solutions portfolio, SEO projects, custom software development, digital transformation projects" />
      </Helmet>
      <section class="py-5">
        <div class="container mb-5">
          <div class="text-center mb-5">
            <h1 class="display-5 fw-bolder mb-0"><span class="text-gradient d-inline">Our Projects</span></h1>
          </div>
          <div class="row gx-5 justify-content-center">
            <div class="col-lg-11 col-xl-9 col-xxl-8">
              <div class="card overflow-hidden shadow rounded-4 border-0 mb-5">
                <div class="card-body p-0">
                  <div class="d-flex align-items-center">
                    <div class="row gy-5 p-5">
                      <div class="col-lg-12 d-flex justify-content-center">
                        <h3 class="fw-bolder mb-3">Japan Direct (Car Auction Website)</h3>

                      </div>
                      <div class="col-lg-12 d-flex justify-content-center">

                        <img class="img-fluid project-img" src="../images/WhatsApp Image 2024-04-27 at 9.44.36 PM (1).jpeg"
                          alt="..." height="100" />
                      </div>
                      <div class="col-lg-12 d-flex justify-content-center mt-4">
                        <Link class="btn btn-success main-btn w-50" to="JapanDirect">
                          More Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card overflow-hidden shadow rounded-4 border-0 mb-5">
                <div class="card-body p-0">
                  <div class="d-flex align-items-center">
                    <div class="row gy-5 p-5">
                      <div class="col-lg-12 d-flex justify-content-center">
                        <h3 class="fw-bolder mb-3">Margarita Shipping (Transport Website)</h3>

                      </div>
                      <div class="col-lg-12 d-flex justify-content-center">

                        <img class="img-fluid project-img" src="../images/WhatsApp Image 2024-06-24 at 3.21.58 PM.jpeg"
                          alt="..." height="100" />
                      </div>
                      <div class="col-lg-12 d-flex justify-content-center mt-4">
                        <Link class="btn btn-success main-btn w-50" to="Transworld">
                          More Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card overflow-hidden shadow rounded-4 border-0 mb-5">
                <div class="card-body p-0">
                  <div class="d-flex align-items-center">
                    <div class="row gy-5 p-5">
                      <div class="col-lg-12 d-flex justify-content-center">
                        <h3 class="fw-bolder mb-3">Custome Web Based ERP</h3>

                      </div>
                      <div class="col-lg-12 d-flex justify-content-center">

                        <img class="img-fluid project-img" src="../images/Untitled.png"
                          alt="..." height="100" />
                      </div>
                      <div class="col-lg-12 d-flex justify-content-center mt-4">
                        <Link class="btn btn-success main-btn w-50" to="erp">
                          More Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card overflow-hidden shadow rounded-4 border-0 mb-5">
                <div class="card-body p-0">
                  <div class="d-flex align-items-center">
                    <div class="row gy-5 p-5">
                      <div class="col-lg-12 d-flex justify-content-center">
                        <h3 class="fw-bolder mb-3">Software House Website</h3>

                      </div>
                      <div class="col-lg-12 d-flex justify-content-center">

                        <img class="img-fluid project-img" src="../images/WhatsApp Image 2024-07-09 at 12.51.34 AM.jpeg"
                          alt="..." height="100" />
                      </div>
                      <div class="col-lg-12 d-flex justify-content-center mt-4">
                        <Link class="btn btn-success main-btn w-50" to="CodianWeb">
                          More Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card overflow-hidden shadow rounded-4 border-0 mb-5">
                <div class="card-body p-0">
                  <div class="d-flex align-items-center">
                    <div class="row gy-5 p-5">
                      <div class="col-lg-12 d-flex justify-content-center">
                        <h3 class="fw-bolder mb-3">Water Supply Management System</h3>

                      </div>
                      <div class="col-lg-12 d-flex justify-content-center">

                        <img class="img-fluid project-img" src="../images/Untitled design (7).png"
                          alt="..." height="100" />
                      </div>
                      <div class="col-lg-12 d-flex justify-content-center mt-4">
                        <Link class="btn btn-success main-btn w-50" to="WaterMange">
                          More Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}