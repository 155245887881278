import { useEffect } from 'react';
import React from "react";
import { Helmet } from 'react-helmet';

export default function Erp() {
  useEffect(() => {

    document.title = 'Customize ERP'
  });
  return (
    <div>
      <Helmet>
        <meta property="title" content="Psoft - Customized ERP Development" />
        <meta name="description" content="Optimize your business with Psoft’s ERP development solutions. Our customized ERP systems can streamline finance, HR, inventory, and more, providing a unified platform to enhance efficiency and growth." />
        <meta name="keywords" content="ERP development, customized ERP software, enterprise resource planning, business management software, ERP solutions, finance management, inventory control, HR software, streamline operations, Psoft ERP" />

      </Helmet>
      <section class="about_section service layout_padding">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="detail-box">
                <div class="heading_container">
                  <h2>ERP Development</h2>
                </div>
                <p>
                  Have a successfull company! and headache to manage it so now
                  develop a software to manage finance, inventory, HR etc,
                  Running a successful company is a remarkable achievement, but
                  it often comes with the headache of managing numerous aspects
                  like finance, inventory, HR, and more. That's where our ERP
                  (Enterprise Resource Planning) development services step in.
                  We empower you to take control, streamline your operations,
                  and scale your business efficiently with a tailored software
                  solution.
                  <b> Why ERP Matters </b>
                  As your company grows, managing various functions becomes
                  increasingly complex. ERP software acts as the nerve center of
                  your organization, bringing together essential processes into
                  a unified system. It eliminates manual work, reduces errors,
                  and allowing you to make informed decisions and achieve
                  sustainable growth.
                </p>
                <a href="" data-toggle="modal" data-target="#quote">Get Free consultaion</a>              </div>
            </div>
            <div class="col-md-6 ">
              <div class="img-box">
                <img src="../images/erp-img.png" alt="website image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
