import { useEffect } from 'react';
import React from "react";
import { Helmet } from 'react-helmet';

export default function Seo() {
  useEffect(() => {

    document.title = 'On Page SEO'
  })
  return (
    <div>
      <Helmet>
        <meta property="title" content="Psoft - On-Page SEO Services for High Rankings" />
        <meta name="description" content="Boost your website's visibility with Psoft’s on-page SEO optimization. Enhance your rankings, increase organic traffic, and improve online success by optimizing critical SEO elements on your website." />
        <meta name="keywords" content="on-page SEO, SEO optimization, search engine ranking, website visibility, organic traffic, SEO services, keyword optimization, Psoft SEO" />
      </Helmet>
      <section class="about_section service layout_padding">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="detail-box">
                <div class="heading_container">
                  <h2>On Page SEO</h2>
                </div>
                <p>
                  We also offer on page SEO optimization to enhance your website
                  ranking, In today's competitive digital landscape, having a
                  beautiful website is only part of the equation for online
                  success. To truly stand out and drive organic traffic, your
                  website needs to rank high on search engine results pages
                  (SERPs). That's where our on-page SEO optimization services
                  come into play.
                  <b> Why On-Page SEO Matters </b>
                  On-page SEO (Search Engine Optimization) is the foundation of
                  a strong online presence. It involves optimizing various
                  elements within your website to ensure that search engines
                  like Google understand your content and rank it higher for
                  relevant keywords. When your website ranks well, it becomes
                  more discoverable, leading to increased visibility, organic
                  traffic, and potential sales.
                </p>
                <a href="" data-toggle="modal" data-target="#quote">Get Free consultaion</a>              </div>
            </div>
            <div class="col-md-6 ">
              <div class="img-box">
                <img src="../images/seo-img.png" alt="website image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
