import React from "react";
import { useEffect } from 'react';
import { Helmet } from "react-helmet";
export default function E_commerce() {
  useEffect(() => {

    document.title = 'E-commerce Store'
  })
  return (
    <div>
      <Helmet>
        <meta property="title" content="Psoft - E-commerce Store Solutions" />
        <meta name="description" content="Build your online store with Psoft’s E-commerce solutions. We offer affordable, engaging platforms designed to enhance your digital presence, boost sales, and create a dynamic shopping experience for your customers." />
        <meta name="keywords" content="e-commerce store solutions, online store development, digital shopping experience, affordable e-commerce, Psoft e-commerce, online marketplace, increase online sales, digital storefront, custom e-commerce development" />

      </Helmet>
      <section class="about_section service layout_padding">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="detail-box">
                <div class="heading_container">
                  <h2>E-commerce Store</h2>
                </div>
                <p>
                  Being a store owner online and get a great online presence to
                  sell your product for an affordable price, As a store owner,
                  embracing the digital age is essential to thriving in today's
                  market. With our E-commerce Store solutions, we empower you to
                  establish a online presence that not only showcases your
                  products but also creates an engaging shopping experience for
                  your customers. And the best part? We offer all of this at an
                  affordable price. <b>The Power of an E-commerce Store </b>
                  In a world where consumers increasingly turn to the internet
                  to shop, your E-commerce Store is your virtual marketplace.
                  It's more than just an online catalog; it's a dynamic platform
                  that can catapult your sales, broaden your customer base, and
                  strengthen your brand.
                </p>
                <a href="" data-toggle="modal" data-target="#quote">
                  Get Free consultaion
                </a>
              </div>
            </div>
            <div class="col-md-6 ">
              <div class="img-box">
                <img src="../images/e-commerce-img.png" alt="website image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
