// CustomLoadingBar.js
import React, { useRef, useEffect } from "react";
import LoadingBar from "react-top-loading-bar";
import { useLocation } from "react-router-dom";

const height ={
    height:'1vh',
}
const CustomLoadingBar = () => {
  const ref = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const unlisten = ref.current;
    ref.current.continuousStart();

    return () => {
      unlisten.complete();
    };
  }, [location]);

  return <LoadingBar color="lightgreen" ref={ref}/>;
};

export default CustomLoadingBar;
