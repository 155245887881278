import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom"; // Import useLocation for routing

const myStyle = {
  borderRadius: '0px',
};
export default function Footer() {
  const location = useLocation(); // Use useLocation to get the current path

  const [formDataQuote, setFormDataQuote] = useState({
    name: "",
    phone_number: "",
    email: "",
    project_type: "",
    budget_range: "", // Add budget_range
    timeline: "", // Add timeline
    project_desc: "", // Add project_desc
  });

  const handleInputChangeQuote = (event) => {
    const { name, value } = event.target;
    setFormDataQuote((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const Quote = async (event) => {
    event.preventDefault();
    const btn = document.getElementById("btn");
    btn.disabled = true;
    btn.innerHTML = "Submiting...";
    const apiAddress = "https://laravelreact.psofts.online/api/add-quote"; // Replace with your actual API address

    const response = await axios.post(
      apiAddress,
      formDataQuote
    );
    btn.innerHTML = "Submit";
    btn.disabled = false;

    if (response.data.status === 200) {
      Swal.fire({
        icon: "success",
        title: response.data.message,
        text: "We will contact you soon.",
        focusConfirm: false,
      });
      const closeButton = document.querySelector('[data-dismiss="modal"]');
      if (closeButton) {
        closeButton.click();
      }
      setFormDataQuote({
        name: "",
        phone_number: "",
        email: "",
        project_type: "",
        budget_range: "", // Add budget_range
        timeline: "", // Add timeline
        project_desc: "", // Add project_desc
      });
    }
    if (response.data.status === 500) {
      Swal.fire({
        icon: "warning",
        title: "An error occurred!",

        focusConfirm: false,
      });
    }
  };

  const [formDataNewsletter, setFormDataNewsletter] = useState({
    email: "",
  });

  const handleInputChangeNewsletter = (event) => {
    const { name, value } = event.target;
    setFormDataNewsletter((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const Newsletter = async (event) => {
    event.preventDefault();
    console.log(formDataNewsletter);
    const newletter = document.getElementById("subscribe");
    newletter.disabled = true;
    newletter.innerHTML = "Subscribi...";
    const response = await axios.post(
      "https://laravelreact.psofts.online/api/add-newsletter",
      formDataNewsletter
    );
    newletter.innerHTML = "Subscribe";

    if (response.data.status === 200) {
      setFormDataNewsletter({
        email: "",
      });
      document.getElementById('news-email').value = ''
      newletter.innerHTML = "Successfully Subscribed";
    }
    if (response.data.status === 500) {
      newletter.innerHTML = response.data.error;
      newletter.disabled = false;
    }
    if (response.data.status === 422) {
      newletter.innerHTML = response.data.error;
      newletter.disabled = false;
    }
  };
  return (
    <div>
      <div class="footer_container">
        {/* <!-- info section --> */}

        <section class="info_section ">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-lg-3 ">
                <div class="info_detail">
                  <h4>PSOFT</h4>
                  <p>
                    Psoft, one of the growing websites in the world, provides
                    complete dynamic websites, and readymade products that may
                    helped many businesses grow their business and generate new
                    revenue streams.
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-lg-2 mx-auto">
                <div class="info_link_box">
                  <h4>Links</h4>
                  <div class="info_links">
                    <Link class="" to="/">
                      Home
                    </Link>
                    <Link class="" to="about">
                      About
                    </Link>
                    <Link class="" to="services">
                      Services
                    </Link>
                    <Link class="" to="contact-us">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-3 ">
                <h4>Subscribe</h4>
                <form id="newsletter" onSubmit={Newsletter}>
                  <input
                    type="email"
                    placeholder="Enter email"
                    onChange={handleInputChangeNewsletter}
                    id="news-email"
                    name="email"
                  />
                  <button type="submit" id="subscribe">
                    Subscribe
                  </button>
                </form>
              </div>
              <div class="col-md-6 col-lg-3 mb-0 ml-auto">
                <div class="info_contact">
                  <h4>Contact</h4>
                  <div class="contact_link_box">
                    <a href="tel:+923152177398">
                      <i class="fa fa-phone" aria-hidden="true"></i>
                      <span>Call +92 315 2177 398</span>
                    </a>
                    <a href="mailto:info@psofts.online">
                      <i class="fa fa-envelope" aria-hidden="true"></i>
                      <span>info@psofts.online</span>
                    </a>
                  </div>
                </div>
                <div class="info_social">
                  <Link to="https://www.facebook.com/profile.php?id=100089586951476&mibextid=ZbWKwL">
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                  </Link>
                  <Link to="https://www.pinterest.com/psoftmain">
                    <i class="fa fa-pinterest" aria-hidden="true"></i>
                  </Link>
                  <Link to="https://www.linkedin.com/company/psoft-software/">
                    <i class="fa fa-linkedin" aria-hidden="true"></i>
                  </Link>
                  <Link to="">
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- end info section --> */}

        {/* <!-- footer section --> */}
        <footer class="footer_section">
          <div class="container">
            <p>
              &copy; <span id="displayYear"></span> All Rights Reserved By <Link to="/"> www.psofts.online</Link>
              {/* <Link to="https://html.design/">Free Html Templates</a> */}
            </p>
          </div>
        </footer>
        {/* <!-- footer section --> */}
      </div>

      <div class="modal fade" id="quote">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
              <h4>Quote</h4>
              <form class="modal-body" onSubmit={Quote}>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        class="form-control "
                        id=""
                        onChange={handleInputChangeQuote}
                        name="name"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        class="form-control "
                        id=""
                        onChange={handleInputChangeQuote}
                        name="phone_number"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        class="form-control "
                        id=""
                        onChange={handleInputChangeQuote}
                        name="email"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Project Type</label>
                      <select
                        id="project_type"
                        onChange={handleInputChangeQuote}
                        name="project_type"
                        class="form-control"
                        required
                      >
                        <option value=""></option>
                        <option value="1">Business Web App</option>
                        <option value="2">E-commerce Store</option>
                        <option value="3">ERP Development</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Budget Range</label>
                      <select
                        id="budget_range"
                        onChange={handleInputChangeQuote}
                        name="budget_range"
                        class="form-control"
                        data-style="btn-primary" required
                      >
                        <option value=""></option>
                        <option value="1">100$ to 1500$</option>
                        <option value="2">1500$ to 5000$</option>
                        <option value="3">5000$ to More</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Project Timeline</label>
                      <select
                        id="timeline"
                        onChange={handleInputChangeQuote}
                        name="timeline"
                        class="form-control"
                        required
                      >
                        <option value=""></option>
                        <option value="1">Less than one month</option>
                        <option value="2">1 to 3 Months</option>
                        <option value="3">3 to 6 Months</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Project Description</label>
                      <textarea
                        id="project_desc"
                        onChange={handleInputChangeQuote}
                        name="project_desc"
                        class="form-control"
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-success" id="btn">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
